// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { _getUrls } from '@imprivata-cloud/common';

export const MOCKSERVER_DEFAULT_URL = 'http://localhost:1080';

declare global {
  interface Window {
    _appConfig: Record<string, string>;
  }
}

export interface AppEnvConfig {
  API_URL: string;
  ZIPKIN_TRACING_URL?: string;
  OTLP_TRACING_URL?: string;
  COLLECT_TRACING?: string;
  TRACING_SOLUTION_TYPE?: string;
}

let envConfig: AppEnvConfig;

export function _getEnvVariable(
  window: Window,
  env: ImportMetaEnv,
  name: string,
  defaultValue?: string,
): string | undefined {
  const reactAppEnvName = `REACT_APP_${name}`;
  if (
    window._appConfig !== undefined &&
    Object.prototype.hasOwnProperty.call(window._appConfig, name)
  ) {
    return window._appConfig[name];
  }

  if (
    Object.prototype.hasOwnProperty.call(env, reactAppEnvName) ||
    Object.prototype.hasOwnProperty.call(env, name)
  ) {
    return env[reactAppEnvName] ?? env[name];
  }

  return defaultValue;
}

export function _initAppConfig(
  window: Window,
  env: ImportMetaEnv,
): AppEnvConfig {
  const { apiUrl, tracingUrl } = _getUrls();
  return {
    API_URL:
      apiUrl ?? (_getEnvVariable(window, env, 'API_URL', apiUrl) as string),
    ZIPKIN_TRACING_URL: _getEnvVariable(window, env, 'ZIPKIN_TRACING_URL'),
    OTLP_TRACING_URL: _getEnvVariable(
      window,
      env,
      'OTLP_TRACING_URL',
      tracingUrl,
    ),
    COLLECT_TRACING: _getEnvVariable(window, env, 'COLLECT_TRACING'),
  };
}

export default function getConfig(): AppEnvConfig {
  if (envConfig === undefined) {
    envConfig = _initAppConfig(window, import.meta.env);
  }
  return envConfig;
}
